import { useContext } from "react";
import { ConfigContext, ResponseContext, UserContext } from "../context";
import { formatDollars } from "../utils";

// Just displays the response data in a nice table
export function ResponseDisplay({resData}){
    const contextData = useContext(ResponseContext);
    const data = resData || contextData;
    const userData = useContext(UserContext);
    const config = useContext(ConfigContext);

    const getPaymentDescription = () => {
        const plan = config.payment_options?.find(option => option?.name === data?.payment_plan);
        return plan?.name;
    }

    const getTotalPurchaseAmount = () => {
        const plan = config.payment_options?.find(option => option?.name === data?.payment_plan);
        return plan?.type === "payment_plan" ?
            formatDollars(Number(config.purchase_price_usd) * data.shares_to_purchase * 1.0002 + 1)
            : formatDollars(Number(config.purchase_price_usd) * data.shares_to_purchase)
    }   

    return (
        <div className="flex-col align-center">
            {userData.is_admin ?
                <b className="Red m-6 self-center">Admin responses are not actually stored and disappear on close or signout</b>
                : <b className="self-center my-6">Response recorded at {data.response_time}</b>
            }
            <table className="flex-col maxw">
            <tbody>
                <tr>
                    <td className="no-wrap">Address</td>
                    <td className="maxw">
                        {data?.addr_l1}
                        {"addr_l2" in data ? <><br/><p>{data?.addr_l2}</p><br/></>: null}
                        {data?.city}, {data?.state} {data?.zip}, {data?.country}
                    </td>
                </tr>
                <tr>
                    <td className="no-wrap">Employee ID</td>
                    <td>{userData.emp_id}</td>
                </tr>
                <tr>
                    <td className="no-wrap">Company Code</td>
                    <td>{userData.company_code}</td>
                </tr>
                <tr>
                    <td className="no-wrap">Shares Purchased</td>
                    <td>{data.shares_to_purchase} shares</td>
                </tr>
                <tr>
                    <td className="no-wrap">Total Payment Amount</td>
                    <td>{getTotalPurchaseAmount()} (Includes ACH fees, if applicable)</td>
                </tr>
                <tr>
                    <td className="no-wrap">Payment Type</td>
                    <td>{getPaymentDescription()}</td>
                </tr>
                <tr>
                    <td className="no-wrap">Personal Email Address</td>
                    <td>{data?.personal_email}</td>
                </tr>
                {"personal_phone" in data && data.personal_phone !== "" ? <tr>
                    <td className="no-wrap">Personal Phone Number</td>
                    <td>{data?.personal_phone}</td>
                </tr> : null}
            </tbody>
        </table>
        </div>
    )
}