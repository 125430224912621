import { useContext, useEffect, useState } from "react";
import Layout from "../components/layout";
import { formatDollars, useToast } from "../utils";
import { ResponseDisplay } from "../components/response";
import { ConfigContext, UserContext } from "../context";

export default function EnrollPage() {
    const [enrollStep, setEnrollStep] = useState(1);
    const userData = useContext(UserContext);
    const config = useContext(ConfigContext);
    const [toast, setToast] = useToast();

    const [inputs, setInputs] = useState({
        shares_to_purchase: 0,
        country: "US",
        addr_l1: "",
        addr_l2: "",
        city: "",
        state: "",
        zip: ""
    });

    // Update the initial input states when the context receives its update
    useEffect(() => {
        setInputs(values => ({...values, ...{
            addr_l1: userData.addr_l1 || "",
            addr_l2: userData.addr_l2 || "",
            city: userData.city || "",
            state: userData.state || "",
            zip: userData.zip || "",
            emp_id: userData.emp_id || (userData.is_admin ? "ADMIN" : undefined),
            work_email: userData.work_email || (userData.is_admin ? "ADMIN" : undefined),
            name_first: userData.name_first || (userData.is_admin ? "ADMIN" : undefined),
            name_last: userData.name_last || (userData.is_admin ? "ADMIN" : undefined)
        }}))
    }, [userData])


    const [responseSubmission, setResponseSubmission] = useState({});
    const handleSubmit = async (event) => {
        event.preventDefault();
        if(enrollStep === 4){
            const total_purchase_amount = Number(config.purchase_price_usd) * inputs.shares_to_purchase;
            await fetch("/api/responses", {
                    method: "POST",
                    body: JSON.stringify({...inputs, total_purchase_amount}),
                    credentials: "include"
                })
                .then(async data => {
                    if(data.status === 200){
                        const body = await data.text();
                        console.log(body);
                        window.sessionStorage.setItem("response", body);
                        setResponseSubmission(JSON.parse(body));
                        setEnrollStep(enrollStep + 1); // Only move on if responses get saved
                    } else {
                        setToast("Error! You can click submit again or close browser tab and re-enroll. If error further persists, contact support")
                    }
                }).catch(e => { // TODO: Show actual error
                    setToast("Could not submit response, close this browser tab and try again. If error persists, contact support");
                    console.log(e, "Failed to save user responses");
                })
        } else if(enrollStep === 5){
            document.location = "/main";
        } else {
            setEnrollStep(enrollStep + 1);
        }
    }

    const handleChange = (event) => {
        var value;
        switch(event.target.type){
            case "radio": value = event.target.id; break;
            case "text": value = event.target.value; break;
            case "checkbox": value = event.target.checked; break;
            case "number": value = Math.max(0, parseInt(event.target.value)); break;
            default: break;
        }
        // Limit the number of shares to be purchased
        let shares = Math.min( // Minimum of the entry or the max_shares_payment_type
            (event.target.name === "shares_to_purchase" ? value : inputs.shares_to_purchase) || 0, // Use the current input if necessary
            parseInt(userData?.[`max_shares_` + 
                optionFinder(event.target.name === "payment_plan" ? value : inputs.payment_plan)?.type]
                || (userData.is_admin ? "1" : "0"))
        )
        // Wipe out the email field if they type in their work email
        if(event.target.name === "personal_email" && userData.work_email &&
            event.target.value.toLowerCase() === userData.work_email.toLowerCase()){
            value = "";
        }
        setInputs({...inputs, [event.target.name]: value, shares_to_purchase: shares})
    }

    // Finds the payment plan config based on the name
    const optionFinder = (planName) => {
        return config.payment_options?.find(option => option.name === planName)
    }

    // Email Validation
    const validEmail = (email) => {
        var pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return (email || "").toLowerCase() !== (userData.work_email || "").toLowerCase() && pattern.test(email)
    }

    // Phone validation
    const validPhone = (phone) => {
        let valid = Array.from(phone || "").every(c => "()+x-0123456789 ".includes(c));
        let numsOnly = Array.from(phone || "").filter(c => "0123456789".includes(c)).join("");
        return valid && numsOnly.length >= 10;
    }

    return (
        <Layout toast_text={toast}>
            <div className="flex-col">
                <EnrollmentStep title="Address Confirmation" enrollStep={enrollStep} currStep="1">
                    <form className="flex-col maxw my-16" onSubmit={handleSubmit}>
                        <p className="Bold my-8">Below is the address that will be provided to JPMorgan to set up your share account. Please correct if necessary.</p>
                        <input type="text" className="my-12" placeholder="Address Line 1" name="addr_l1" autoComplete="address-line1" value={inputs.addr_l1} onChange={handleChange} />
                        <input type="text" placeholder="Address Line 2" name="addr_l2" autoComplete="address-line2" value={inputs.addr_l2} onChange={handleChange} />
                        <div className="flex-row justify-evenly my-12">
                            <input className="maxw" type="text" placeholder="City" name="city" autoComplete="address-level2" value={inputs.city} onChange={handleChange} />
                            <input className="maxw mx-16" type="text" placeholder="State" name="state" autoComplete="address-level1" value={inputs.state} onChange={handleChange} />
                            <input className="maxw mx-16 ml-0" type="text" placeholder="Zip" name="zip" autoComplete="postal-code" value={inputs.zip} onChange={handleChange} />
                            <input className="maxw" type="text" placeholder="Country" name="country" autoComplete="country" value={inputs.country} onChange={handleChange} />
                        </div>
                        <input type="submit" value="Continue" disabled={!inputs.addr_l1 || !inputs.city || !inputs.state || !inputs.zip}/>
                    </form>
                </EnrollmentStep>
                <EnrollmentStep title="Purchase Selections" enrollStep={enrollStep} currStep="2">
                    <form className="flex-col maxw my-16" onSubmit={handleSubmit}>
                        <p className="Bold my-12">Choose your payment type, enter the quantity of shares to purchase, and review your PURCHASE SUMMARY</p>
                        <p className="Bold my-8">
                            If you choose the lump sum payment option, your total purchase cannot exceed 1500 shares or more than 25% of
                            your estimated {config.plan_year} annual gross salary (bonus, at target,
                            included). Sanofi has calculated your maximum purchasable shares with these options to
                            be <span className="Green-back">{userData["max_shares_payment_plan"]}</span> shares.
                        </p>
                        {Object.keys(config.payment_options || {})
                            .filter(k => config.payment_options[k].type === "payment_plan")
                            .map(key => { // Populate the payment plan options
                                const option = config.payment_options[key];
                                let optionName = option.name;
                                return (
                                    <div key={optionName} className="flex-row my-12">
                                        <input className="m-8" type="radio" id={optionName} name="payment_plan" onChange={handleChange}/>
                                        <label htmlFor={optionName}>{config.payment_options[key].description}</label>
                                    </div>
                                )
                        })}
                        <p className="my-6"/>
                        <p className="Bold my-8">
                            You can instead choose to pay via payroll deductions.
                            With this option, your total purchase cannot exceed the lesser of $10,000 or 10%
                            of your net monthly pay. Sanofi has calculated your maximum purchasable shares with this option to
                            be <span className="Green-back">{userData["max_shares_payroll_deductions"]}</span> shares.
                        </p>
                        {Object.keys(config.payment_options || {})
                            .filter(k => config.payment_options[k].type === "payroll_deductions")
                            .map(key => { // Populate the payment plan options
                                const option = config.payment_options[key];
                                let optionName = option.name;
                                return (
                                    <div key={optionName} className="flex-row my-12">
                                        <input className="m-8" type="radio" id={optionName} name="payment_plan" onChange={handleChange}/>
                                        <label htmlFor={optionName}>{config.payment_options[key].description}</label>
                                    </div>
                                )
                        })}
                        <p className="my-6"/>
                        <p className="Bold my-4">Choose your payment method and then enter the number of shares you wish to purchase</p>
                        <input type="number" onWheel={(e) => e.target.blur()} name="shares_to_purchase" value={String(inputs.shares_to_purchase)} onChange={handleChange}></input>
                        <p className="my-6"/>
                        <p className="self-center my-8"><b>PURCHASE SUMMARY:</b> With your discounted price per share of {formatDollars(config.purchase_price_usd)}, purchasing {String(inputs.shares_to_purchase)} shares
                            will cost {formatDollars(Number(config.purchase_price_usd) * inputs.shares_to_purchase)}
                            {inputs.payment_plan !== undefined ?
                                (config.payment_options?.[inputs.payment_plan]?.num_payments === 1 ?
                                    " as a lump sum " :
                                    ` in ${optionFinder(inputs.payment_plan)?.num_payments} payments of ${formatDollars(Number(config.purchase_price_usd) * inputs.shares_to_purchase / optionFinder(inputs.payment_plan)?.num_payments)} `
                            ): " "}
                            and you will receive {Math.min(4, Math.floor(inputs.shares_to_purchase / 5))} free matching shares.
                            {optionFinder(inputs.payment_plan)?.type === "payment_plan" ?
                                <span> Your ACH fee will be {formatDollars(Number(config.purchase_price_usd) * inputs.shares_to_purchase * .0002 + 1)}</span>
                            : null}
                        </p>
                        <input type="submit" value="Continue" disabled={!inputs.payment_plan || !inputs.shares_to_purchase}/>
                    </form>
                </EnrollmentStep>
                <EnrollmentStep title="Payment Agreement" enrollStep={enrollStep} currStep="3">
                    <form className="flex-col maxw my-16" onSubmit={handleSubmit}>
                        <CheckboxOption name="costCollectionAgreed" onChange={handleChange}>
                            <span className="Title">I acknowledge and agree to all of the following:</span>
                        </CheckboxOption>
                        <ul>
                            <li className="my-8">If I elect for payroll deductions, I authorize Sanofi to deduct the total amount of my purchase from my paychecks, in essentially equal installments, over a period of 22 pay periods (or until the amount is fully paid.)</li>
                            <li className="my-8">If I should cease work due to an unpaid leave, I authorize Sanofi to take one or more double payroll deductions upon my return to work in order to bring my account back into balance.</li>
                            <li className="my-8">If I should transfer to a different Sanofi entity that is on a different payroll system, I will be responsible to pay the entire remaining balance that is due on my purchase at the time of transfer with one lump sum payment.</li>
                            <li className="my-8">If I selected the lump sum payment option, I agree to pay Sanofi the full amount, for the quantity of shares that I selected, in one lump sum payment, by {config.LS_due_date}.  If I fail to make my payment, I agree that Sanofi can, at their discretion, cancel this share purchase or authorize the custodian of the shares purchased in this plan, to sell the quantity necessary to cover the amount that I owe plus all related fees and sale costs then due the custodian.</li>
                            <li className="my-8">If Sanofi must sell my shares from this plan to cover a balance that I owe related to this purchase and the sale of my shares is not enough to cover the amount owed, I agree that Sanofi may deduct the remaining balance from my paychecks.  Further, my account file will be turned over to Sanofi Employee Relations for review and I will be excluded from participating in future share purchases at Sanofi.</li>
                            <li className="my-8">If, prior to {config.release_date}, my employment with Sanofi terminates or I request a release of the shares purchased in this plan by applying for an early exit, as per the plan rules, I agree to pay Sanofi, upon demand, any unpaid balance due for the purchase of my shares plus any required unpaid tax withholding. If I am no longer employed with Sanofi and I fail to pay the balance owed by the due date, I authorize the custodian of my shares to sell the quantity of my shares from this plan which are necessary to cover my remaining purchase balance and/or taxes plus all related fees and sale costs then due to the custodian. If the sale of my shares is not enough to pay my obligations to Sanofi, I will be required to pay Sanofi the remaining balance, and, if I fail to do so, Sanofi will be entitled to pursue all legal remedies available.</li>
                        </ul>
                        <input type="submit" value="Continue" disabled={!inputs.costCollectionAgreed}/>
                    </form>
                </EnrollmentStep>
                <EnrollmentStep title="Participation Acknowledgements" enrollStep={enrollStep} currStep="4">
                    <form className="flex-col maxw my-16" onSubmit={handleSubmit}>
                        <b>BY CHECK MARKING ALL BOXES BELOW AND SUBMITTING THIS ENROLLMENT FORM, I HEREBY REPRESENT AND WARRANT TO SANOFI AND AGREE AS FOLLOWS</b>
                        <CheckboxOption name="confirmation1" onChange={handleChange}>I have received a copy of the <a rel="noopener noreferrer" target="_blank" href={config.plan_brochure_link}>Plan Brochure</a> and <a rel="noopener noreferrer" target="_blank" href={config.prospectus_us_link}>Plan Prospectus - US</a> (or <a rel="noopener noreferrer" target="_blank" href={config.prospectus_puerto_rico_link}>Plan Prospectus - Puerto Rico</a>) describing the offering under the Plan and I understand and agree to the terms and conditions of the Plan, as described in these two documents</CheckboxOption>
                        <CheckboxOption name="confirmation2" onChange={handleChange}>I have the legal capacity and authority to enroll in {config.plan_name} and undertake the obligations described in the Plan Brochure and Prospectus.</CheckboxOption>
                        <CheckboxOption name="confirmation3" onChange={handleChange}>I have received a copy of the {parseInt(config.plan_year) - 1} Annual Report on <a rel="noopener noreferrer" target="_blank" href={config.form_20f_link}>Form 20F</a> filed by Sanofi with the U.S. Securities and Exchange commission, which contains financial and other information concerning Sanofi.</CheckboxOption>
                        <CheckboxOption name="confirmation4" onChange={handleChange}>I have been given the opportunity to ask questions of and receive answers from Sanofi relating to the Plan and its terms and conditions, as I consider sufficient, for me to make an informed decision to participate in the Plan.</CheckboxOption>
                        <CheckboxOption name="confirmation5" onChange={handleChange}>I hereby appoint Sanofi as my attorney-in-fact and agent to transfer or direct the transfer of any such shares on the books of any transfer agent, registrar, or person performing similar functions of power being coupled with an interest.</CheckboxOption>
                        <CheckboxOption name="confirmation6" onChange={handleChange}>Sanofi has made no representations or commitments to me that are inconsistent with the terms and conditions of the Plan as described in the Plan Brochure and Prospectus.</CheckboxOption>
                        <CheckboxOption name="confirmation7" onChange={handleChange}>I am eligible to purchase shares under the Plan as described in the Plan Brochure and Prospectus.</CheckboxOption>
                        <CheckboxOption name="confirmation8" onChange={handleChange}>I have read the <a rel="noopener noreferrer" target="_blank" href={config.tax_implications_link}>Tax Implications Communication</a> and understand that my decision to enroll and participate in the Plan will have an immediate tax impact. I agree to honor this obligation and understand that these taxes will be deducted from my paychecks over four pay periods.</CheckboxOption>
                        <CheckboxOption name="confirmation9" onChange={handleChange}>I have determined that the benefits and risks of participating in this Plan are appropriate for me and my personal financial and investment goals.</CheckboxOption>
                        <CheckboxOption name="confirmation10" onChange={handleChange}>This enrollment constitutes a purchase order and I understand that any prior enrollment forms I have submitted, either electronic or paper form, will be superseded by this enrollment form immediately upon submission.</CheckboxOption>
                        <CheckboxOption name="confirmation11" onChange={handleChange}>I hereby submit the investment choices, representations and agreements described above and throughout this web enrollment process. I understand and acknowledge that as of 11:59 EDT on {config.plan_close_date}, my purchase will become irrevocable as per the plan rules and that if I wish to cancel this purchase I must do so before this deadline.</CheckboxOption>
                        <p className="Bold my-16 mb-0">Enter Personal Email Address. <span className="Red">DO NOT USE {userData.work_email}</span> (If this email is incorrect, contact us)</p>
                        <input className="my-8" type="text" name="personal_email" placeholder="Personal Email Address" value={inputs.personal_email || ""} onChange={handleChange}></input>
                        <p className="Bold my-16 mb-0">Enter Personal Phone Number</p>
                        <input className="my-8" type="text" name="personal_phone" placeholder="Personal Phone Number" value={inputs.personal_phone || ""} onChange={handleChange}></input>
                        <p className="Red my-16 mt-0">Your personal email address and phone number will be used by our Equity Administration Partners (Stock & Option Solutions) to communicate with you about your equity account in the event that you leave Sanofi.</p>
                        <p className="self-center text-center">PURCHASE SUMMARY: You elected to purchase <b>{String(inputs.shares_to_purchase)} shares</b> for a total purchase amount of <b>{
                            optionFinder(inputs.payment_plan)?.type === "payment_plan" ?
                                formatDollars(Number(config.purchase_price_usd) * inputs.shares_to_purchase * 1.0002 + 1)
                                : formatDollars(Number(config.purchase_price_usd) * inputs.shares_to_purchase)
                            }</b> (Includes ACH fees, if applicable)
                            <br/>You have elected to pay for your shares through <b>{inputs.payment_plan}</b></p>
                        <input type="submit" value="Confirm Purchase Enrollment"
                            disabled={!validEmail(inputs.personal_email) || !validPhone(inputs.personal_phone) || !inputs.confirmation1 || !inputs.confirmation2 || !inputs.confirmation3 || !inputs.confirmation4 || !inputs.confirmation5 || !inputs.confirmation6 || !inputs.confirmation7 || !inputs.confirmation8 || !inputs.confirmation9 || !inputs.confirmation10 || !inputs.confirmation11}
                        />
                    </form>
                </EnrollmentStep>
                <EnrollmentStep no_indicator={true} title={<>Enrollment Confirmation - <span className="Red">Please Print For Your Records</span></>} enrollStep={enrollStep} currStep="5">
                    <form className="flex-col maxw my-16" onSubmit={handleSubmit}>
                        <p>Thank you, your enrollment has been recorded, as shown below. Make sure to print or save a copy of this page for your records. On the main menu, you have the option to change or cancel this enrollment at any time, up until the enrollment closes. Once enrollment closes, no changes can be made.</p>
                        <p className="my-8"><b>LUMP SUM PAYMENTS:</b>  Log back into this website after enrollment closes to access the payment portal.  All lump sums must be made on or  before {config.LS_due_date} as the payment portal will close at 11:59pm on {config.LS_due_date}.  All lump sum payments will include an ACH fee of .02% + $1.00.   If your payment is not made, then, at the sole discretion of Sanofi, either your share purchase will be cancelled or your shares sold to pay the amount you owe, plus administrative fees and all costs of the share sale due to the custodian.  </p>
                        <p className="my-8"><b>PAYROLL DEDUCTIONS:</b>  Your payment will be spread over 22 pay periods and will begin with your first paycheck in October and run through your last paycheck in July of next year</p>
                        <p className="my-8"><b>TAXES:</b>  Your income taxes will be divided among and deducted from your 4 paychecks in August & September</p>
                        <p className="my-8"><b>MATCHING SHARES:</b>  Any matching shares which you are eligible to receive as per the plan documents, will show on your final confirmation statement which will be emailed to you within 7 days after enrollment ends</p>
                        <div className="self-center"><ResponseDisplay resData={responseSubmission}/></div>
                        <button className="my-12" onClick={(e) => { e.preventDefault(); window.print(); }}>Print Receipt</button>
                        <input type="submit" value="Return to Home"/>
                    </form>
                </EnrollmentStep>
            </div>
        </Layout>
    );
}

function EnrollmentStep(props){
    return (
        <div className="flex-col my-2">
            <div className="flex-row justify-between align-center card-square">
                <p className="Title">{props.title}</p>
                { !props.no_indicator ? 
                    <span className="flex-row justify-center align-center">
                        <span className="mx-6">{Number(props.enrollStep) > Number(props.currStep) ? "Complete" : "Incomplete"}</span>
                        <span className={Number(props.enrollStep) > Number(props.currStep) ? "green-dot" : "red-dot"}></span>
                    </span>
                : null}
            </div>
            {Number(props.enrollStep) === Number(props.currStep) ? <div className="card-square">
                {props.children}
            </div> : null }
        </div>
    )
}

function CheckboxOption(props){
    return (
        <div className="flex-row my-16 mb-0">
            <input className="m-8" type="checkbox" name={props.name} onChange={props.onChange}/>
            <label className="self-center" htmlFor={props.name}>
                {props.children}
            </label>
        </div>
    )
}