import { BrowserRouter, Routes, Route } from "react-router-dom";
import EnrollPage from './pages/enroll';
import LoginPage from './pages/login';
import MenuPage from './pages/menu';
import Error404Page from './pages/404page';
import AdminPage from './pages/admin';
import PaymentPage from './pages/pay';
import { ConfigContext, ResponseContext, UserContext} from "./context";
import { getConfig, getUserData, getUserResponse } from "./context";
import { useEffect, useState } from "react";
import { logout } from "./utils";

export default function App() {
    // If the browser session has changed, logout
    if(!["logged", "valid"].includes(window.sessionStorage.getItem("login"))
        || (window.location.pathname !== "/" && window.sessionStorage.getItem("login") === "logged")){
        logout();
    }
    // Everything should use www subdomain
    if(!window.location.host.includes("www.") && !window.location.host.includes("localhost:") && !window.location.host.includes("pages.dev")){
        let segs = window.location.host.split(".");
        let domain = "www." + segs.slice(segs.length - 2).join(".");
        window.location.replace(domain + window.location.href.pathname);
    }
    // Get all of the data once and supply it to all of the components through a single context
    const [userData, setUserData] = useState({});
    const [configData, setConfigData] = useState({});
    const [responseData, setResponseData] = useState({});
    useEffect(() => {
        console.log("Refreshing data");
        getUserData().then(setUserData);
        getConfig().then(setConfigData);
        getUserResponse().then(setResponseData);
    }, [])
    return (
        <UserContext.Provider value={userData}>
            <ConfigContext.Provider value={configData}>
                <ResponseContext.Provider value={responseData}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/">
                                <Route index element={<LoginPage />} />
                                <Route path="main" element={<MenuPage />} />
                                <Route path="enroll" element={<EnrollPage />} />
                                <Route path="admin" element={<AdminPage />} />
                                <Route path="pay" element={<PaymentPage />} />
                                <Route path="*" element={<Error404Page />} />
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </ResponseContext.Provider>
            </ConfigContext.Provider>
        </UserContext.Provider>
    );
}
