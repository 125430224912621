import { createContext } from "react";
import { logout } from "./utils";

export const UserContext = createContext({});
export const ConfigContext = createContext({});
export const ResponseContext = createContext({});

export async function getConfig() {
    let storedData = JSON.parse(window.sessionStorage.getItem("texts"));
    return storedData ||
        fetch("/api/texts")
            .then(async data => {
                if (data.status === 200) {
                    const parsed = await data.json();
                    window.sessionStorage.setItem("texts", JSON.stringify(parsed));
                    return parsed;
                } else { // Just log the error
                    console.log(await data.text());
                    return {};
                }
            }).catch(e => {
                console.log(e, "Api request for text elements failed")
                return {};
            })
}

export async function getUserData() {
    let storedData = JSON.parse(window.sessionStorage.getItem("user"));
    return storedData ||
        fetch("/api/users", {
            credentials: "include"
        })
            .then(async data => {
                if (data.status === 200) {
                    const parsed = await data.json();
                    window.sessionStorage.setItem("user", JSON.stringify(parsed));
                    return parsed;
                } else {
                    if(window.location.pathname !== "/") logout(); // Logout if we're not already there
                    console.log(await data.text());
                    return {};
                }
            }).catch(e => {
                console.log(e, "Api request for user data failed");
                return {};
            })
}

export async function getUserResponse() {
    let storedData = JSON.parse(window.sessionStorage.getItem("response"));
    return storedData ||
        fetch("/api/responses", {
            credentials: "include"
        })
            .then(e => e.json())
            .then(data => {
                window.sessionStorage.setItem("response", JSON.stringify(data))
                return data;
            }).catch(e => {
                console.log(e, "Api request for user response failed");
                return {};
            });
}