import { useState } from "react";
import Layout from "../components/layout";
import { useToast } from "../utils";

export default function LoginPage() {

    // If the user is already logged in, in this tab, send them to main
    if(window.sessionStorage.getItem("login") === "valid"){
        window.location.pathname = "/main";
    }

    const [inputs, setInputs] = useState({});
    const [toast, setToast] = useToast();

    const handleSubmit = async (event) => {
        event.preventDefault();
        await fetch("/api/users", {
            method: "POST",
            body: JSON.stringify(inputs),
            credentials: "include"
        })
            .then(async data => {
                const body = await data.text();
                try{
                    const parsed = JSON.parse(body);
                    window.sessionStorage.setItem("user", JSON.stringify(parsed));
                    window.sessionStorage.setItem("login", "valid"); // Wiped when the page is left
                    document.location = "/main"; // Redirect to the main page
                } catch {
                    setToast(body); // Show the error as the toast
                }
            }).catch(e => {
                console.log(e, "Login Failed, unknown error");
            })
    }

    const handleChange = (event) => {
        setInputs(values => ({...values, [event.target.name]: event.target.value}))
    }

    return (
        <Layout toast_text={toast}>
            <div className="maxw flex-col">
                <div className="flex-col align-center maxw my-16">
                    <b className="my-16 Title">Login to Enrollment Portal</b>
                    <form className="flex-col align-center maxw" onSubmit={handleSubmit}>
                        <input className="maxw my-16" type="text" placeholder="Sanofi Network Login ID (Username used on your Sanofi Computer)" name="net_login_id" value={inputs.net_login_id || ""} onChange={handleChange} />
                        <div className="flex-row maxw my-12 mt-0">
                            <input className="maxw mx-16 ml-0" type="password" placeholder="Last 4 Digits of Social Security Number" name="l4ssn" value={inputs.l4ssn || ""} onChange={handleChange} />
                            <input className="maxw" type="password" placeholder="Last 2 Digits of Birth Year" name="l2yob" value={inputs.l2yob || ""} onChange={handleChange} />
                        </div>
                        <p className="Red my-16">ATTENTION: We use the last 4 digits of your social security number & last 2 digits of your birthyear instead of a password</p>
                        <p className="Red my-16 mb-0">ELIGIBILITY TO ENROLL: 3 months employed with Sanofi as of the plan close date above</p>
                        <input type="submit" value="Login" className="maxw my-12" disabled={!inputs.net_login_id || !inputs.l4ssn || !inputs.l2yob}/>
                    </form>
                </div>
            </div>
        </Layout>
    );
}