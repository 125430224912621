import { useState } from "react";
import Layout from "../components/layout";
import { ResponseDisplay } from "../components/response";

export default function PaymentPage() {
    const [agreed, setAgreed] = useState(false);
    return (
        <Layout>
            <ResponseDisplay/>
            <p className="Red my-12 maxw">
            ATTENTION:  You will need the information on this page to fill out the payment form.  Please be very careful when entering your account number into the payment form, as rejected or declined transactions will incur a $25 fee.  The payment amount above reflects the total of your purchase plus an ACH fee of .02% of your purchase + $1.00.  If you have any problems making your payment or questions prior to making your payment, please email <a href="mailto:stockadministration@sos-team.com">stockadministration@sos-team.com</a>.   
            </p>
            <form className="flex-col maxw" onSubmit={(e) => {
                    e.preventDefault();
                    window.open("https://quickclick.com/r/15zmz");
                }}>
                <div className="flex-row my-12 mt-0">
                    <input className="m-8" type="checkbox" name="agree" onChange={(e) => setAgreed(e.target.checked)}/>
                    <label className="self-center" htmlFor="agree">
                        I have read and understood the above message
                    </label>
                </div>
                <input type="submit" disabled={!agreed} className="maxw" value="Open the Secure Payment Portal"/>
            </form>
        </Layout>
    );
}