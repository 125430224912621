import { useContext } from "react";
import Layout from "../components/layout";
import { ResponseDisplay } from "../components/response";
import { useToast } from "../utils";
import { ConfigContext, ResponseContext } from "../context";

export default function MenuPage() {
    const config = useContext(ConfigContext);
    const [toast, setToast] = useToast();

    return (
        <Layout toast_text={toast}>
            <div className="flex-col">
                <PhaseEntry set_toast={setToast}/>
                <div className="flex-row justify-evenly align-start maxw">
                    <div className="flex-col justify-evenly align-center mx-16 mr-0 maxw">
                        <div className="card flex-col py-16 maxw bbox">
                            <p className="Title self-center">Plan Resources</p>
                            <b>Navigate to the following links to view or download. You must be on the Sanofi network to view the documents</b>
                            <ul>
                                <li><a rel="noopener noreferrer" target="_blank" href={config.enrollment_instructions_link}>Enrollment Instructions</a></li>
                                <li><a rel="noopener noreferrer" target="_blank" href={config.plan_faqs_link}>Frequently Asked Questions</a></li>
                            </ul>
                            <b>Important Documents to Read and Save</b>
                            <ul>
                                <li><a rel="noopener noreferrer" target="_blank" href={config.plan_brochure_link}>Plan Brochure</a></li>
                                <li><a rel="noopener noreferrer" target="_blank" href={config.prospectus_us_link}>Plan Prospectus - U.S.</a></li>
                                <li><a rel="noopener noreferrer" target="_blank" href={config.prospectus_puerto_rico_link}>Plan Prospectus - Puerto Rico</a></li>
                                <li><a rel="noopener noreferrer" target="_blank" href={config.tax_implications_link}>Tax Implications Communication</a></li>
                                <li><a rel="noopener noreferrer" target="_blank" href={config.form_20f_link}>Sanofi SEC Form 20F Filing</a></li>
                            </ul>
                            <b>More Information</b>
                            <ul>
                                <li><a rel="noopener noreferrer" target="_blank" href={config.slide_deck_link}>Action {config.plan_year} Presentation Powerpoint</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex-col justify-evenly align-center mx-16 maxw">
                        <div className="card flex-col py-16 my-16 mt-0 maxw bbox">
                            <p className="Title self-center">Action {config.plan_year} Purchase Price</p>
                            <p>Reference Price: €{config.reference_price_eur} / ${config.reference_price_usd}</p>
                            <span>Purchase Price: €{config.purchase_price_eur} / ${config.purchase_price_usd}<p className="Red">*</p></span>
                            <b className="self-center my-16 mb-0">Free Matching Share Contribution</b>
                            <table className="maxw">
                                <thead>
                                    <tr>
                                        <td>Shares Purchased</td>
                                        <td>Free Shares</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td>1-4</td><td>0</td></tr>
                                    <tr><td>5-9</td><td>1</td></tr>
                                    <tr><td>10-14</td><td>2</td></tr>
                                    <tr><td>15-19</td><td>3</td></tr>
                                    <tr><td>20+</td><td>4</td></tr>
                                </tbody>
                            </table>
                            <span className="my-8">
                                <p className="Red">*</p> The shares offered in this plan are Sanofi's French Ordinary shares. For current stock price details
                                see <a rel="noopener noreferrer" target="_blank" href="https://live.euronext.com/en/product/equities/FR0000120578-XPAR">Sanofi on Euronext</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

function PhaseEntry({set_toast}){
    const config = useContext(ConfigContext);
    console.log("Rerender entry", config.plan_phase);
    const response = useContext(ResponseContext);
    const plan = config?.payment_options?.find(option => option?.name === response?.payment_plan);

    const cancelEnrollment = () => {
        if(window.confirm("Are you sure you want to cancel your enrollment? You will still be able to re-enroll until enrollment closes")){
            fetch("/api/responses", {
                    method: "DELETE",
                    credentials: "include"
                })
                .then(data => {
                    if(data.status === 200){
                        window.sessionStorage.removeItem("response")
                        window.location.pathname = "/main";
                    } else {
                        set_toast("Failed to delete enrollment, close browser window and try again")
                    }
                }).catch(e => {
                    console.log(e, "Api request for text elements failed")
                })
        }
    }

    return (
        <div className="card flex-col mx-16 my-16 mt-0 align-center">
            <p className="Title">Enrollment Status</p>
            {response?.shares_to_purchase && response.shares_to_purchase !== "CANCELLED" ?
                // If they have a response and it isn't cancelled, display options
                <>
                    <ResponseDisplay/>
                    {config?.plan_phase === "enrollment" ? 
                        <div className="flex-row maxw my-12">
                            <button className="maxw" onClick={() => window.location = "/enroll"}>Redo Enrollment</button>
                            <button className="maxw mx-12" onClick={cancelEnrollment}>Cancel Enrollment</button>
                            <button className="maxw" onClick={window.print}>Print Enrollment Receipt</button>
                        </div>
                        : (plan?.type === "payroll_deductions" ?
                            <p>You enrolled in payroll deductions, no further action is currently needed</p>
                            : <button className="maxw my-12" onClick={() => window.location = "/pay"}>Begin Payment</button>
                        )
                    }
                </>
            : (config?.plan_phase === "enrollment" ?
                        <>
                            <p>You have not yet enrolled</p>
                            <button className="maxw my-12" onClick={() => window.location = "/enroll"}>Start Enrollment</button>
                        </>
                        : <p>You did not enroll in Sanofi Action {config.plan_year}. Enrollment is already closed</p>
                )
            }
        </div>
    )
}