import { useContext } from "react";
import { ConfigContext, UserContext } from "../context";
import { logout } from "../utils";
import "./layout.css"

export default function Layout(props){
    const config = useContext(ConfigContext);
    const userData = useContext(UserContext);
    return (
        <div>
            {props.toast_text ? 
                <div id="toastBar" className="flex-col maxw align-center justify-center text-center bbox">{props.toast_text}</div>
            : null}
            <div id="contentWrapper">
                <div className="mx-16">
                    <header className="flex-col">
                        <div className="flex-row justify-between align-center">
                            <img src={config.main_logo_link} id="logoImg" alt="Sanofi Action Logo"/>
                            <div className="flex-col justify-center align-center card my-16">
                                <p className="Title">Employee Stock Purchase Plan Enrollment</p>
                                <p className="m-2">Opens <span className="Red my-2">{config.plan_open_date}</span></p>
                                <p className="m-2">Closes <span className="Red my-2">{config.plan_close_date}</span> at 11:59pm EDT</p>
                            </div>
                        </div> 
                        {window.location.pathname !== "/" && (config?.plan_phase !== "closed" || userData?.is_admin) ?
                            <NavBar/> : null}
                    </header>
                    {window.location.pathname === "/" || config?.plan_phase !== "closed" || userData?.is_admin ?
                        props.children
                        : <p className="text-center self-center Title maxw">Website has been closed by plan administrators</p>
                    }
                </div>
            </div>
            <footer className="flex-col maxw align-center justify-center text-center bbox">
                <span>
                    <b>Contact Us</b>
                    <span> by email at </span>
                    <a href="mailto:stockadministration@sos-team.com">stockadministration@sos-team.com</a>
                    <span> or by phone, at </span>
                    <a href="tel:+18777959771">+1 (877) 795-9771</a>
                    <span> for any questions</span><br/>
                    <span>We're happy to help with any issue Monday through Friday, 10am-8pm EDT during enrollment</span>
                </span>
            </footer>
        </div>
    )
};

function NavBar(){
    var { name_first, name_last, is_admin } = useContext(UserContext);
    return (
        <nav>
            <div className="flex-row justify-between align-center my-16">
                <p className="Title">Welcome: <b>{name_first} {name_last}</b></p>
                {window.location.pathname !== "/main" ?
                    <button onClick={()=> window.location.pathname = "/main"}>Main Menu</button> : null}
                {is_admin && window.location.pathname !== "/admin" ?
                    <button onClick={()=> window.location.pathname = "/admin"}>Admin Portal</button> : null}
                <button onClick={logout}>Logout</button>
            </div>
        </nav>
    )
}