/* eslint-disable no-loop-func */
import { useContext, useEffect, useRef, useState } from "react";
import Layout from "../components/layout";
import { useToast } from "../utils";
import { ConfigContext, UserContext } from "../context";

export default function AdminPage() {

    // Don't let users visit the admin page
    const { name_first, is_admin } = useContext(UserContext);
    if(name_first && window.location.pathname === "/admin" && !is_admin){
        window.location.pathname = "/main";
    }

    const [toast, setToast] = useToast();
    return ( // These are all sub-components that are defined below
        <Layout toast_text={toast}>
            <UserLoginResetter set_toast={setToast}/>
            <SiteTextConfigurator set_toast={setToast}/>
            <UserFileUploader set_toast={setToast}/>
            <DataExporter set_toast={setToast}/>
            <DataDeleter set_toast={setToast}/>
        </Layout>
    );
}

function SiteTextConfigurator(props){
    // Initialize states to be used
    const no_edit_keys = ["plan_phase", "payment_options"]; // These keys require their own configurators
    const initialConfig = useContext(ConfigContext);
    // These will be set by context
    const [inputs, setInputs] = useState({});

    // When the context receives and update it will set the initial state of the inputs
    useEffect(() => {
        setInputs(values => ({...values, ...initialConfig}))
    }, [initialConfig]);

    // Handles the changes in all of the normal text fields as well as the plan phase selection
    const handleChange = (event) => {
        console.log(event.target.name, event.target.value);
        setInputs(values => ({...values, [event.target.name]: event.target.value}))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        await fetch("/api/import/texts", {
                method: "POST",
                body: JSON.stringify(inputs),
                credentials: "include"
            })
            .then(e => e.json())
            .then(data => {
                window.sessionStorage.setItem("texts", JSON.stringify(data));
                window.location.reload();
            }).catch(e => { // TODO: Show actual error
                props.set_toast("Failed to save config information");
                console.log(e, "Failed to save config information");
            })
    }

    const handleReset = (event) => {
        setInputs(values => ({...values, [event.target.name]: initialConfig[event.target.name]}))
    }
    
    const handleDelete = (event) => {
        let state = {...inputs};
        delete state[event.target.name];
        setInputs(state)
    }
    
    const handleAddPlan = () => {
        setInputs(values => ({...values, payment_options: [...values.payment_options, {}]}))
    }

    // Handle the ability to add a new text input
    const [new_key, setNewKey] = useState("");
    const handleNewInput = (event) => {
        setNewKey(event.target.value);
    }
    const addNewField = () => {
        if(!(new_key in initialConfig)){ // Don't allow repeating keys
            setInputs(values => ({...values, [new_key]: ""}))
            setNewKey("");
        }
    }

    // Keep track of whether our text data is current
    let saved = Object.keys(initialConfig).every((key) => inputs[key] === initialConfig[key])

    return (
        Object.keys(inputs).length !== 0 ? <>
        <div className="flex-row justify-between align-center">
            <p className="Title">Site Configuration</p>
            <span className="flex-row justify-center align-center">
                <span className="mx-6">{saved ? "Saved" : "Unsaved"}</span>
                <span className={saved ? "green-dot" : "red-dot"}></span>
            </span>
        </div>
        <form className="flex-col maxw my-16" onSubmit={handleSubmit}>
            <span className="my-16 mt-0"><b>Site Text Configuration:</b> <p className="Red">All links must start with "https://" and if you make a mistake just refresh the page</p></span>
            {Object.keys(inputs).filter(k => !no_edit_keys.includes(k)).map((key) => {
                return (
                    <div className="flex-row align-center my-12 mt-0" key={key}>
                        <label htmlFor={key} className="no-wrap">{key}: </label>
                        <input className="maxw mx-12 mr-0" type="text" placeholder={key} name={key} value={inputs[key]} onChange={handleChange} />
                        <button className="mx-12" type="button" name={key} onClick={handleReset} disabled={inputs[key] === initialConfig[key]}>Reset</button>
                        <button type="button" name={key} onClick={handleDelete}>Remove</button>
                    </div>
                    );
                })}
            <div className="flex-row maxw my-12 mt-0">
                <input className="maxw mx-12 ml-0" type="text" placeholder="Name for new stored text field" name="new_field" value={new_key} onChange={handleNewInput} />
                <button type="button" disabled={new_key === ""} onClick={addNewField}>+</button>
            </div>
            <b className="my-16 mb-0">Plan Phase Configuration</b>
            {/* Special plan phase selector */}
            <div className="flex-col maxw">
                <div className="flex-row align-center">
                    <input className="m-8" type="radio" id="enrollment" value="enrollment" name="plan_phase" defaultChecked={initialConfig.plan_phase === "enrollment"} onChange={handleChange}/>
                    <label htmlFor="enrollment">Enrollment</label>
                </div>
                <div className="flex-row align-center">
                    <input className="m-8" type="radio" id="payment" value="payment" name="plan_phase" defaultChecked={initialConfig.plan_phase === "payment"} onChange={handleChange}/>
                    <label htmlFor="payment">Payment</label>
                </div>
                <div className="flex-row align-center">
                    <input className="m-8" type="radio" id="closed" value="closed" name="plan_phase" defaultChecked={initialConfig.plan_phase === "closed"} onChange={handleChange}/>
                    <label htmlFor="closed">Closed</label>
                </div>
            </div>
            {/* Special payment option editor */}
            <div className="flex-row align-center justify-between">
                <b className="my-16 mb-0">Payment Option Configuration</b>
                <button type="button" className="my-12 mb-0"onClick={handleAddPlan}>+ Add New Payment Plan</button>
            </div>
            {
                inputs?.payment_options?.map?.((option, index) => {
                    const planEdited = (e) => {
                        const old = [...inputs.payment_options];
                        old[index][e.target.name.split(" ")[0]] = e.target.value;
                        setInputs(values => ({...values, payment_options: old}))
                    }

                    const planDelete = (e) => {
                        const old = [...inputs.payment_options];
                        old.splice(index, 1);
                        setInputs(values => ({...values, payment_options: old}))
                    }

                    return(
                        <div className="flex-col" key={index}>
                            <div className="flex-row align-center justify-between my-12">
                                <b>Plan {index + 1}</b>
                                <button type="button" className="my-12 mb-0"onClick={planDelete}>- Delete Plan</button>
                            </div>
                            <div className="flex-col mx-16 mr-0">
                                <div className="flex-row align-center">
                                    <p className="no-wrap">Plan Type: </p>
                                    <div className="flex-row align-center">
                                        <input className="m-8" type="radio" id={`payment_plan ${index}`} value="payment_plan" name={`type ${index}`} defaultChecked={option.type === "payment_plan"} onChange={planEdited}/>
                                        <label htmlFor={`payment_plan ${index}`}>Payment Plan</label>
                                    </div>
                                    <div className="flex-row align-center">
                                        <input className="m-8" type="radio" id={`payroll_deduction ${index}`} value="payroll_deductions" name={`type ${index}`} defaultChecked={option.type === "payroll_deductions"} onChange={planEdited}/>
                                        <label htmlFor={`payroll_deduction ${index}`}>Payroll Deductions</label>
                                    </div>
                                </div>
                                <div className="flex-row align-center my-12 mb-0">
                                    <label className="no-wrap">Plan Title: </label>
                                    <input className="maxw mx-12 mr-0" type="text" placeholder="Plan title: this should match the title on the payment portal" name="name" value={option.name || ""} onChange={planEdited} />
                                </div>
                                <div className="flex-row align-center my-12">
                                    <label className="no-wrap">Plan Description: </label>
                                    <input className="maxw mx-12 mr-0" type="text" placeholder="Plan description: i.e. A lump sum check mailed to Sanofi..." name="description" value={option.description || ""} onChange={planEdited} />
                                </div>
                                <div className="flex-row align-center">
                                    <label className="no-wrap">Number of Payments: </label>
                                    <input className="maxw mx-12 mr-0" type="number" onWheel={(e) => e.target.blur()} placeholder="Number of payments: i.e. 1" name="num_payments" value={option.num_payments || ""} onChange={planEdited} />
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            <input type="submit" value="Save All Config Changes" className="my-12" disabled={saved}/>
        </form>
        </> : null
    )
}

function UserFileUploader(props){
    const inputFile = useRef(null);
    const [fileData, setFileData] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [saved, setSaved] = useState(true);

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Convert the raw CSV file data to JSON format, then batch the uploads
        const lines = fileData
            .replaceAll("\r", "\n") // Replace carriage returns with newlines
            .replaceAll("\n\n", "\n") // Don't allow double newlines
            .split("\n").map(line => line.trim().split(","))
        console.log(lines);
        const headers = lines[0];
        const users = lines.filter((l, i) => i > 0 && l[0].length > 2).map(bits => {
            let user = {}
            headers.forEach((header, index) => user[header] = bits[index])
            return user;
        })
        await fetch("/api/import/users", {
                method: "POST",
                body: JSON.stringify(users),
                credentials: "include"
            })
            .then(async res => {
                if(res.status !== 200){
                    props.set_toast(await res.text());
                    console.log("File upload failed", res.body)
                } else {
                    setSaved(true);
                }
            }).catch(e => {
                props.set_toast("Failed to upload user file, unknown error");
                console.log(e, "File upload failed");
            })
    }

    const selectFile = () => {
        inputFile.current.click();
    }

    const handleFileChange = (event) => {
        if(event.target.files){ // Make sure the user actually selected a file
            setFileName(event.target.files[0].name);
            setSaved(false);
            const reader = new FileReader()
            reader.onload = (contents) => {
                setFileData(contents.target.result);
            }
            reader.readAsText(event.target.files[0]);
        }
    }

    return (
        <>
            <div className="flex-row my-16 mb-0 align-center justify-between">
                <p className="Title">Upload Eligibility File</p>
                <span className="flex-row justify-center align-center">
                    <span className="mx-6">{saved ? "Saved" : "Unsaved"}</span>
                    <span className={saved ? "green-dot" : "red-dot"}></span>
                </span>
            </div>
            <form className="flex-col maxw my-12" onSubmit={handleSubmit}>
                <div className="flex-col maxw">
                    <p className="Red">1. Create an Excel sheet with at least the following populated columns:</p>
                    <p className="mx-16 mr-0">emp_id, company_code, net_login_id, ssn, date_of_birth, name_last, name_first, work_email, addr_l1, addr_l2, city, state, zip, max_shares_payment_plan, max_shares_payroll_deductions</p>
                    <p className="Red">2. The entire document must be free of commas, quotation marks, and carriage returns</p>
                    <p className="mx-16 mr-0">You can remove carriage returns by entering ctrl+j in the find box and doing a replace all with nothing. The ctrl+j character is invisible, use ctrl+a to select the entire find entry field before entering ctrl+j</p>
                    <p className="Red">3. The document cannot contain any duplicate lines</p>
                    <p className="Red">4. Create a new column "dob" which applies the "=text(##, "mm/dd/yyyy")" formula on the date_of_birth column</p>
                    <p className="Red">5. Round down the share limits to a whole number with no decimals</p>
                    <p className="Red">6. Select the entire sheet, and change format to text (date_of_birth column will be screwed up, that's ok)</p>
                    <p className="Red">7. In "Save As" menu, save it as a ".csv" file</p>
                    <p className="Red">8. Upload that file below and be patient, upload can take up to 30 seconds</p>
                </div>
                <div className="flex-row maxw my-12">
                    <input type='file' ref={inputFile} style={{display: "none"}} onChange={handleFileChange}/>
                    <button type="button" className="maxw mx-12 ml-0" onClick={selectFile}>{fileData ? "Select different file" : "Select eligibility file"}</button>
                    <input type="submit" className="no-wrap" value={`Process & Upload ${fileName || ""}`} disabled={saved}/>
                </div>
            </form>
        </>
    )
}

function DataExporter(props){
    const [ip, setIp] = useState(false);

    const downloadData = (endpoint) => async () => {
        setIp(true);
        let headers = "";
        let datas = [];
        let cursor = null;
        while(true){
            props.set_toast?.("Data Export In Progress");
            await fetch(
                `/api/export/${endpoint}` + (cursor ? `?cursor=${cursor}` : ""), {
                credentials: "include"
            }).then(async data => {
                if(data.status === 200){
                    const result = await data.json();
                    cursor = result.cursor;
                    headers = result.headers;
                    datas = datas.concat(result.data);
                } else {
                    props.setToast(await data.text())
                }
            })
            console.log(datas.length)
            if(!cursor) break;
        }
        const final = headers + "\n" + datas.join("\n");
        const a = document.createElement('a');
        const blob = new Blob([final], {type: "text/csv"});
        a.download = `${endpoint}_export.csv`;
        a.href = URL.createObjectURL(blob);
        a.addEventListener('click', (e) => {
            setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
        setIp(false);
    }

    return (
        <>
            <div className="flex-row justify-between align-center my-16 mb-0">
                <p className="Title">Export Datafiles</p>
            </div>
            <b className="Red">Downloads can take up to 5 minutes, you must wait on this page for completion</b>
            <div className="flex-row maxw my-8">
                <button disabled={ip} className="maxw mx-12 ml-0" onClick={downloadData("users")}>Download Eligibility CSV File</button>
                <button disabled={ip} className="maxw" onClick={downloadData("responses")}>Download Purchases CSV File</button>
            </div>
        </>
    )
}

function DataDeleter(props) {
    const deleteData = (endpoint) => async () => {
        await fetch(`/api/wipe/${endpoint}`, {
            method: "DELETE",
            credentials: "include"
        })
            .then(async data => {
                if(data.status === 200){
                    props.set_toast(`Entire ${endpoint} table has been deleted!`)
                } else {
                    props.set_toast(await data.text())
                }
            })
    }

    const [checks, setChecks] = useState({})
    const handleChange = (e) => {
        setChecks(values => ({...values, [e.target.name]: e.target.checked}))
    }
    const enabled = checks.check1 && checks.check2 && checks.check3;

    return (
        <>
            <div className="flex-row justify-between align-center my-16 mb-0">
                <p className="Title">Wipe Database Tables</p>
            </div>
            <div className="flex-col maxw my-8">
                <div className="flex-row justify-between maxw">
                    <p className="Red">Click all three check boxes to enable buttons</p>
                    <input className="m-8" type="checkbox" name="check1" value={checks.check1 || false} onChange={handleChange}/>
                    <input className="m-8" type="checkbox" name="check2" value={checks.check2 || false} onChange={handleChange}/>
                    <input className="m-8" type="checkbox" name="check3" value={checks.check3 || false} onChange={handleChange}/>
                </div>
                <div className="flex-row maxw">
                    <button className="maxw mx-12 ml-0" onClick={deleteData("users")} disabled={!enabled}>Delete Entire Eligibility Table</button>
                    <button className="maxw" onClick={deleteData("responses")} disabled={!enabled}>Delete Entire Purchases Table</button>
                </div>
            </div>
        </>
    )
}

function UserLoginResetter(props) {
    // Initialize states to be used
    const [net_id, setNetId] = useState("");
    const [success, setSuccess] = useState(true);

    const handleSubmit = async (event) => {
        event.preventDefault();
        await fetch(`/api/users/reset/${net_id}`, {
                method: "POST",
                credentials: "include"
            })
            .then(async data => {
                if(data.status === 200){
                    setSuccess(true);
                } else {
                    props.set_toast(await data.text());
                }
            }).catch(e => {
                props.set_toast("Failed to reset login attempts, unknown error");
                console.log(e, "Failed to reset user login");
            })
    }

    const handleChange = (event) => {
        setNetId(event.target.value);
        setSuccess(event.target.value === "");
    }

    return (
        <>
            <div className="flex-row justify-between align-center">
                <p className="Title">Reset User Login Attempts</p>
                <span className="flex-row justify-center align-center">
                    <span className="mx-6">{success ? "Saved" : "Unsaved"}</span>
                    <span className={success ? "green-dot" : "red-dot"}></span>
                </span>
            </div>
            <form className="flex-row maxw my-16" onSubmit={handleSubmit}>
                <input className="maxw mx-12 ml-0" type="text" placeholder="Employee Network Login ID" value={net_id} onChange={handleChange} />
                <input type="submit" value="Reset" disabled={success}/>      
            </form>
        </>
    )
}