import { useState } from "react";

let toastTimer = undefined;

export function useToast(){
    const [text, setText] = useState();
    return [text, (next) => {
        setText(next);
        clearTimeout(toastTimer);
        toastTimer = setTimeout(() => {setText(null)}, 12000);
    }]
}

export function logout(){
    invalidate_JWT();
    window.sessionStorage.removeItem("user");
    window.sessionStorage.removeItem("response");
    window.sessionStorage.removeItem("texts");
    window.sessionStorage.setItem("login", "logged");
    window.location.pathname = "/";
}

export function invalidate_JWT(){
    document.cookie = `jwt_token=wiped; expires=Session; path=/api; SameSite=Strict; Secure;`;
}

export function formatDollars(amount){
    amount = parseFloat(amount); // Allows for strings or numbers
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',}
    )
    return formatter.format(amount);
}