import Layout from "../components/layout";

export default function Error404Page() {
    setTimeout(() => {
        document.location = "/main"
    }, 2000)
    return (
        <Layout>
            <div className="my-16 Title maxw maxh flex-col justify-center align-center">Page Not Found, Redirecting to Main Menu</div>
        </Layout>
    );
}